<template>
  <div class="LoaderWithOverlay">
    <div class="overlay">
      <Loader />
    </div>
  </div>
</template>

<script>
import Loader from '@/components/util/Loader';
export default {
  name: 'LoaderWithOverlay',
  components: {
    Loader
  },
};
</script>

<style lang="scss" scoped>
.LoaderWithOverlay {

  .overlay {
    background-color: $global-color-transparent-three;
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 6;
    top: 0;
    left: 0;
  }
}
</style>
