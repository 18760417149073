
<template>
  <div class="AuctionView">
    <div v-if="auctionViewErrors">
      <div class="o-container-gray">
        <ErrorCard :mainMessage="messages.error.auctionViewError"/>
      </div>
      <div class="o-container-gray">
        <md-button @click="Helpers.goToRoute('auctions', $router); auctionViewResetError()" class="md-raised md-primary">{{ messages.error.auctionViewErrorAuctionsLink }}</md-button>
      </div>
    </div>
    <div v-else>
      <div class="o-container-gray">
        <h3 class="o-default-h3">{{ $t('auctionView.bidFor') }} <span class="highlighted">{{ $route.params.domain }}.{{ $route.params.tld }}</span></h3>
        <section class="container-full-white countdown-bid-section">
          <h5 class="o-default-container-title o-default-h4">{{ $t('auctionView.timeLeft') }}</h5>
          <countdown v-if="auction.secondsLeft" :left-time="auction.secondsLeft * 1000">
            <span slot="process"
                  slot-scope="{ timeObj }">
              <section class="countdown">
                <div class="countdown-item">
                  <span class="countdown-item--value">{{ showDaysNo(timeObj.d) }}</span>
                  <span class="countdown-item--label">{{ showDaysLabel(timeObj.d) }}</span>
                </div>
                <div class="countdown-item">
                  <span class="countdown-item--value">{{ showHoursNo(timeObj.h) }}</span>
                  <span class="countdown-item--label">{{ showHoursLabel(timeObj.h) }}</span>
                </div>
                <div class="countdown-item">
                  <span class="countdown-item--value">{{ showMinutesNo(timeObj.m) }}</span>
                  <span class="countdown-item--label">{{ showMinutesLabel(timeObj.m) }}</span>
                </div>
                <div class="countdown-item">
                  <span class="countdown-item--value">{{ showSecondsNo(timeObj.s) }}</span>
                  <span class="countdown-item--label">{{ showSecondsLabel(timeObj.s) }}</span>
                </div>
              </section>
            </span>
            <span slot="finish">
              <h3 class="o-default-h3 strong u-red">{{ $t('auctionView.auctionClosedTimeElapsed') }}</h3>
            </span>
          </countdown>

          <p v-if="auction.closeDate" class="o-default-body-small">{{ $t('auctionView.auctionClosesOn') }} {{ auction.closeDate | moment("DD.MM.YYYY") }} {{ $t('auctionView.atHour') }} {{ auction.closeDate | moment("HH:mm") }}</p>

          <md-dialog :md-active.sync="showDialogMinimum">
            <md-dialog-title>{{ $t('auctionView.bidIncrement') }}</md-dialog-title>
            <p class="o-default-body o-dialog-content">{{ $t('auctionView.dialogContent1') }}</p>
            <md-table md-card v-model="normal">
              <md-table-row>
                <md-table-head>{{ $t('auctionView.until') }}</md-table-head>
                <md-table-head>{{ $t('auctionView.minimumAmountPerBid') }}</md-table-head>
              </md-table-row>
              <md-table-row>
                <md-table-cell>{{ normal.hour }}</md-table-cell>
                <md-table-cell>{{ normal.amount }}</md-table-cell>
              </md-table-row>
            </md-table>
            <p class="o-default-body o-dialog-content">{{ $t('auctionView.dialogContent2') }}{{ normal.amount }}{{ $t('auctionView.dialogContent3') }}</p>
            <md-table md-card v-model="extended">
              <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell :md-label="labels.startingWith" md-sort-by="hour">{{ item.hour }}</md-table-cell>
                <md-table-cell :md-label="labels.minimumBid" md-sort-by="amount">{{ item.amount }}</md-table-cell>
              </md-table-row>
            </md-table>
            <p class="o-dialog-content--strong">{{ $t('auctionView.dialogExample') }}</p>
            <md-dialog-actions>
              <md-button class="md-primary" @click="showDialogMinimum = false">{{ $t('common.actions.close') }}</md-button>
            </md-dialog-actions>
          </md-dialog>

          <md-dialog :md-active.sync="showDialogExtended">
            <md-dialog-title>{{ $t('auctionView.extendingTheAuction') }}</md-dialog-title>
            <p class="o-default-body o-dialog-content">{{ $t('auctionView.extAuctionContent1') }}</p>
            <p class="o-default-body o-dialog-content">{{ $t('auctionView.extAuctionContent2') }}</p>
            <p class="o-default-body o-dialog-content">{{ $t('auctionView.extAuctionContent3') }}</p>
            <p class="o-default-body o-dialog-content">{{ $t('auctionView.extAuctionContent4') }}{{ normal.amount }}{{ $t('auctionView.extAuctionContent5') }}</p>
            <md-table md-card v-model="extended">
              <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell :md-label="labels.startingWith" md-sort-by="hour">{{ item.hour }}</md-table-cell>
                <md-table-cell :md-label="labels.minimumBid" md-sort-by="amount">{{ item.amount }}</md-table-cell>
              </md-table-row>
            </md-table>
            <p class="o-dialog-content--strong">{{ $t('auctionView.extAuctionExample') }}</p>
            <md-dialog-actions>
              <md-button class="md-primary" @click="showDialogExtended = false">{{ $t('common.actions.close') }}</md-button>
            </md-dialog-actions>
          </md-dialog>

          <md-button @click="showDialogExtended = true" class="o-default-body-xs auction-info"><i class="material-icons md-16 auction-icon">info</i> {{ messages.extended }}</md-button>

        </section>

        <section class="container-full-white offer-bid-section">
          <LoaderWithOverlay v-if="isDomainListLoading" />

          <div v-if="auction.offer !== null">
            <div class="o-default-h3 auction-offer-title">{{ $t('auctionView.auctionStatus') }}</div>
            <div class="o-default-h4 auction-offer-subtitle">{{ $t('auctionView.currentOffer') }}</div>
            <div class="o-default-h3 auction-offer-value">€{{ auction.offer }}</div>
            <div v-if="auction.winner" class="o-default-h4 auction-offer--success">{{ $t('auctionView.congratulationsHighestOffer') }}</div>
            <div v-else class="o-default-h4 auction-offer--error">{{ $t('auctionView.notTheHighestOffer') }}</div>
          </div>
          <div v-else>
            <div class="o-default-h3 auction-offer-title">{{ $t('auctionView.auctionStatus') }}</div>
            <div class="o-default-h4 auction-offer--error">{{ $t('auctionView.noOffer') }}</div>
          </div>

          <section class="auction-bid-section">
            <div class="o-default-h3 auction-bid-label">{{ $t('auctionView.placeOffer') }}</div>
            <div class="o-default-h3 strong">{{ $route.params.domain }}.{{ $route.params.tld }}</div>
            <div class="auction-bid-input-container">
              <md-field :class="getValidationClass('bidAmount')" class="bid-input-field">
                <label class="u-font-body-regular">{{ $t('auctionView.yourOfferInputLabel') }}</label>
                <md-input v-autofocus
                          v-model.trim="bidAmount"
                          @keyup.enter="validateInput(auction.id)"
                          @blur="validateInputBlur()"
                          type="number"
                          oninput="this.value=this.value.slice(0,5)"
                          id="bidAmount"
                          class="bid-input"
                          name="bid-amount"
                          :min="auction.offer ? auction.offer : 15"
                          step="5" />
                <span class="md-error auction-bid--error u-font-body-regular" v-if="!$v.bidAmount.required">{{ $t('auctionView.inputOfferRequired') }}</span>
                <span class="md-error auction-bid--error u-font-body-regular" v-else-if="!$v.bidAmount.minValue">{{ $t('auctionView.inputMinimumOffer') }}</span>
                <span class="md-error auction-bid--error u-font-body-regular" v-else-if="!$v.bidAmount.maxValue">{{ $t('auctionView.inputMaximumOffer') }} {{ $t('contactEmailInreg') }}</span>
                <span class="md-error auction-bid--error u-font-body-regular" v-else-if="!$v.bidAmount.fiveMultiple">{{ $t('auctionView.inputFiveMultiple') }}</span>
              </md-field>

              <md-button @click="validateInput(auction.id)"
                         class="md-dense md-raised md-primary bid-input-btn">{{ $t('auctionView.sendOffer') }}</md-button>

              <div v-if="auctionErrors" v-html="auctionErrors" class="o-default-h5 u-cursor-pointer auction-offer--error u-red"></div>
            </div> <!-- /.auction-bid-input-container -->

            <div class="auction-extra-info">

              <div @click="showDialogMinimum = true" class="extra-info-item o-default-body-small u-cursor-pointer">
                <i class="material-icons md-16 auction-icon">info</i>
                {{ messages.bidUnit.main }}
                <span class="u-text-underline u-strong o-default-body-xs">{{ messages.bidUnit.details }}</span>
              </div>

              <div @click="showDialogExtended = true" class="o-default-body-small u-cursor-pointer">
                <i class="material-icons md-16 auction-icon">info</i>
                {{ messages.extendedSteps.main }}
                <span class="u-text-underline u-strong o-default-body-xs">{{ messages.extendedSteps.details }}</span>
              </div>

              <div class="o-default-body-small">
                <i class="material-icons md-16 auction-icon">info</i>
                {{ $t('auctionView.extraAuctionInfo3') }}
              </div>

              <div class="o-default-body-small">
                <i class="material-icons md-16 auction-icon">info</i>
                {{ $t('auctionView.extraAuctionInfo4') }}
              </div>

              <div class="o-default-body-small">
                <i class="material-icons md-16 auction-icon">info</i>
                {{ $t('auctionView.extraAuctionInfo5') }}
              </div>

              <div class="o-default-body-small">
                <i class="material-icons md-16 auction-icon">info</i>
                {{ $t('auctionView.extraAuctionInfo6') }}
              </div>

              <div class="o-default-body-small">
                <i class="material-icons md-16 auction-icon">info</i>
                {{ $t('auctionView.extraAuctionInfo7') }}
              </div>
            </div> <!-- /.auction-extra-info -->
          </section> <!-- /.auction-bid-section -->
        </section> <!-- /.container-full-white -->

      </div> <!-- /.o-container-gray -->
    </div>
  </div> <!-- /.AuctionView -->
</template>

<script>
import { mapGetters } from 'vuex';
import store from '@/store';
import { validationMixin } from 'vuelidate';
import {
  required,
  minValue,
  maxValue
} from 'vuelidate/lib/validators';
import {
  FETCH_AUCTION,
  AUCTION_VIEW_RESET_ERROR,
  AUCTION_RESET_ERROR,
  AUCTION_RESET_STATE,
  PLACE_BID,
} from '@/store/actions.type';
import ErrorCard from '@/components/util/ErrorCard';
import LoaderWithOverlay from '@/components/util/LoaderWithOverlay';

export default {
  name: 'AuctionView',
  components: {
    ErrorCard,
    LoaderWithOverlay
  },
  mixins: [validationMixin],
  async beforeRouteUpdate(to, from, next) {
    // Reset state if user goes from /auctions/:id to /auctions
    // The component is not recreated so we use to hook to reset the state.
    await store.dispatch(AUCTION_RESET_STATE);
    return next();
  },
  async beforeRouteEnter(to, from, next) {
    // SO: https://github.com/vuejs/vue-router/issues/1034
    // If user arrives directly to this url, we need to fetch the auction
    await store.dispatch(AUCTION_RESET_STATE);
    if (to.params !== undefined) {
      await store.dispatch(
        FETCH_AUCTION,
        to.params,
      );
    }
    return next();
  },
  async beforeRouteLeave(to, from, next) {
    await store.dispatch(AUCTION_RESET_STATE);
    next();
  },
  data() {
    return {
      normal: {
        hour: '23:00',
        amount: '€5'
      },
      extended: [
        {
          hour: '23:00',
          amount: '€10'
        },
        {
          hour: '23:05',
          amount: '€15'
        },
        {
          hour: '23:10',
          amount: '€20'
        },
        {
          hour: '23:15',
          amount: '€25'
        },
        {
          hour: '23:20',
          amount: '€30'
        },
        {
          hour: '23:25',
          amount: '€35'
        },
        {
          hour: '23:30',
          amount: '€40'
        },
        {
          hour: '23:35',
          amount: '€50'
        },
        {
          hour: '23:40',
          amount: '€75'
        },
      ],
      messages: {
        extended: this.$t('auctionView.extAuctionPreview'),
        bidUnit: {
          main: this.$t('auctionView.extraAuctionInfo1'),
          details: this.$t('common.details')
        },
        extendedSteps: {
          main: this.$t('auctionView.extraAuctionInfo2'),
          details: this.$t('common.details')
        },
        error: {
          auctionViewError: this.$t('auctionView.error.auctionViewError'),
          auctionViewErrorAuctionsLink: this.$t('auctionView.error.auctionViewErrorAuctionsLink'),
        }
      },
      labels: {
        startingWith: this.$t('auctionView.startingWith'),
        minimumBid: this.$t('auctionView.minimumBid')
      },
      showDialogExtended: false,
      showDialogMinimum: false,
      auctionSlug: {
        tld: this.$route.params.tld,
        domain: this.$route.params.domain
      },
      bidAmount: null,
      validationData: {
        // showError: false,
        minValue: 15,
        maxValue: 10001,
      }
    };
  },
  computed: {
    ...mapGetters([
      'auction',
      'auctionErrors',
      'auctionViewErrors',
      'isDomainListLoading'
    ])
  },
  methods: {
    getValidationClass(fieldName) {
      const field = this.$v[fieldName]

      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    },
    showDaysNo(days) {
      if (parseInt(days) > 0) {
        if (parseInt(days) > 1) {
          return `${days}`;
        } else if (parseInt(days) == 1) {
          return `${days}`;
        } else {
          return '';
        }
      }
    },
    showDaysLabel(days) {
      if (parseInt(days) > 0) {
        if (parseInt(days) > 1) {
          return ` ${this.$t('common.date.days')} `;
        } else if (parseInt(days) == 1) {
          return ` ${this.$t('common.date.day')} `;
        } else {
          return '';
        }
      }
    },
    showHoursNo(hours) {
      if (parseInt(hours) > 0) {
        if (parseInt(hours) > 1) {
          return `${hours}`;
        } else if (parseInt(hours) == 1) {
          return `${hours}`;
        } else {
          return '';
        }
      }
    },
    showHoursLabel(hours) {
      if (parseInt(hours) > 0) {
        if (parseInt(hours) > 1) {
          return ` ${this.$t('common.date.hours')} `;
        } else if (parseInt(hours) == 1) {
          return ` ${this.$t('common.date.hour')} `;
        } else {
          return '';
        }
      }
    },
    showMinutesNo(minutes) {
      if (parseInt(minutes) > 0) {
        if (parseInt(minutes) > 1) {
          return `${minutes}`;
        } else if (parseInt(minutes) == 1) {
          return `${minutes}`;
        } else {
          return '';
        }
      }
    },
    showMinutesLabel(minutes) {
      if (parseInt(minutes) > 0) {
        if (parseInt(minutes) > 1) {
          return ` ${this.$t('common.date.min')} `;
        } else if (parseInt(minutes) == 1) {
          return ` ${this.$t('common.date.min')} `;
        } else {
          return '';
        }
      }
    },
    showSecondsNo(seconds) {
      if (parseInt(seconds) > 0) {
        if (parseInt(seconds) > 1) {
          return `${seconds}`;
        } else if (parseInt(seconds) == 1) {
          return `${seconds}`;
        } else {
          return '';
        }
      }
    },
    showSecondsLabel(seconds) {
      if (parseInt(seconds) > 0) {
        if (parseInt(seconds) > 1) {
          return ` ${this.$t('common.date.sec')} `;
        } else if (parseInt(seconds) == 1) {
          return ` ${this.$t('common.date.sec')} `;
        } else {
          return '';
        }
      }
    },
    placeBid(auctionId) {
      const bidPayload = {
        auctionId: auctionId,
        bidAmount: this.bidAmount
      }
      this.$store.dispatch(PLACE_BID, bidPayload);
    },
    resetAuctionError() {
      this.$store.dispatch(AUCTION_RESET_ERROR);
    },
    validateInputBlur() {
      this.$v.$touch()
    },
    validateInput(auctionId) {
      this.resetAuctionError();
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.placeBid(auctionId)
      }
    },
    auctionViewResetError() {
      this.$store.dispatch(AUCTION_VIEW_RESET_ERROR);
    }
  },
  validations() {
    const fiveMultiple = (value) => (value % 5) == 0

    return {
      bidAmount: {
        required,
        minValue: minValue(this.validationData.minValue),
        maxValue: maxValue(this.validationData.maxValue),
        fiveMultiple: fiveMultiple
      },
    };
  }
};
</script>

<style lang="scss" scoped>
.AuctionView {

  .md-field {
    display: inline-block;
    margin: 0;
    width: auto;
  }

  .o-container-gray {
    margin-top: 0;
    padding-top: 3.2rem;
  }

  .o-default-container-title {
    display: inline-block;
    font-weight: bold;
    line-height: 1.5;
  }

  .o-default-container-title:after {
    border-bottom: solid .2rem;
    border-color: $palette-primary-color-one;
    content: '';
    display: block;
    transform: scaleX(0.9);
  }

  .o-default-h3 {
    padding-bottom: 1.6rem;
    text-align: center;

    &.strong {
      font-weight: bold;
    }
  }

  .o-default-h4 {
    text-align: center;
  }

  .auction-bid-input-container {
    display: block;
    margin: 1.6rem 0 1.6rem 0;
    position: relative;

    .auction-offer--error {
      text-align: left;
      margin-top: 4rem;
    }
  }

  .auction-bid-label {
    padding-bottom: .8rem;
  }

  .auction-bid-section {
    background: $palette-secondary-color-two;
    border-radius: .4rem;
    font-weight: bold;
    margin: 3.2rem auto 0;
    max-width: 65rem;
    padding: 2.4rem 3.2rem 3.2rem;
    text-align: center;

    .auction-bid--error {
      bottom: auto;
      height: auto;
    }
  }

  .auction-extra-info {
    margin-top: 4rem;
    text-align: left;

    .extra-info-item {
      margin-bottom: .8rem;
    }
  }

  .auction-icon {
    margin-top: 1rem;
    transform: translate(0, .3rem);
  }

  .auction-info {
    background-color: $palette-secondary-color-two;
    border-radius: .4rem;
    color: $palette-secondary-color-three;
    display: block;
    height: auto;
    margin: 3.2rem auto 1.6rem auto;
    white-space: initial;
  }

  .auction-offer-title {
    padding-bottom: .8rem;
  }

  .auction-offer-value {
    background-color: $palette-secondary-color-two;
    display: inline-block;
    font-weight: bold;
    margin-bottom: 1.6rem;
    padding: .8rem 1.6rem;
  }

  .auction-offer {
    font-weight: bold;

    &--success {
      font-weight: bold;
      color: $palette-success;
      padding-bottom: 0;
    }

    &--error {
      font-weight: bold;
      color: $palette-error;
      padding-bottom: 0;
    }
  }

  .bid-input-field {
    background-color: $white;
    border: none;
    border-radius: .4rem 0 0 .4rem;
    display: inline-block;
    font-family: $font-family-heading-regular;
    font-size: 2rem;
    height: 4.8rem;
    padding-left: .8rem;
    text-align: left;
    width: 72%;
  }

  .bid-input {
    width: 100%;
    padding-right: .8rem;
  }

  .bid-input-btn {
    background-color: $palette-primary-color-four !important;
    border-radius: 0 .4rem .4rem 0;
    box-shadow: none;
    font-family: $font-family-heading-regular;
    height: 4.8rem;
    letter-spacing: .1rem;
    margin: 2rem 0 0;
    text-transform: none;
    width: 60%;

    @include desktop {
      margin: 0;
      width: 28%;
    }
  }

  .container-full-white {
    padding-top: 1.6rem;
    position: relative;
  }

  .countdown {
    padding: 1.6rem 0;
    margin-right: 1.8rem;
    // Font size on mobile devices
    font-size: $font-size-h3;

    @include desktop {
      // Font size on desktop
      font-size: $font-size-h1;
    }

    .countdown-item {
      color: $palette-font-color-one;
      display: inline-block;
      margin-right: 1.2rem;

      &:last-child {
        margin-right: 0;
      }

      .countdown-item--value {
        font-weight: bold;
        margin-right: .4rem;
      }
    }
  }

  .countdown-bid-section {
    padding-bottom: .8rem;
    text-align: center;
  }

  .offer-bid-section {
    text-align: center;
  }

  .highlighted {
    font-weight: bold;
    color: $palette-primary-color-two;
  }
}
</style>
